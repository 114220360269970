@charset "utf-8";
@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

.masthead a:visited, .sidebar a:visited {
  color: #494e52!important;
}

html {
 /* Убирает прокрутку по горизонтале, для мобильной версии */
 overflow-x: hidden;
 /*margin-bottom: 0!important;*/
}

#main {
  @include breakpoint($x-large) {
    max-width: 1920px;
  }
}

body {
    background-color: #FFFFFF !important;
  
    @media screen and (max-width: 1025px) {
      font-size:1.2em;
      line-height: 1.3em;
    }  
}

h2, h3 {
  text-align: left !important;
  margin: 1.2em 0;
}

.author__content {
  text-align: center !important;
}

.page {
  /*margin: 10px;*/
}

.page__footer-container{
  border-top: 1px solid #bdc1c4;
  background-color: #f2f3f3;
  width: 100vw;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  z-index: 999;
}

.page__footer {
  max-width: 1280px;
  border: 0;
  padding: 2em 4em;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: unset;

    @media screen and (max-width: 500px) {
      position: unset;
      height: auto!important;
      margin: 0!important;
      padding: 0 2em;
    }
}

.page__footer-follow li {
    @media screen and (max-width: 500px) {
      display: block!important;
      font-size: 1.1em!important;
      margin-left: 20px;
    }
}

.page__footer-follow li+li:before {
      @media screen and (max-width: 500px) {
        padding-right: 0!important;
    }
}

.page__footer-follow, .page__footer-copyright {
        @media screen and (max-width: 500px) {
        margin: 0.7em 0.7em 0 0.7em;
    }

}

.page__footer footer {
    @media screen and (max-width: 500px) {
      max-width: 100vw!important;
    }

}

details {
  margin: 0.5rem 0;
  padding: 0 1.2rem;
  width: 100%;
  position: relative;
  border: 1px solid #78909C;
  border-radius: 6px;
  background-color: #ECEFF1;
  color: #263238;
  transition: background-color .15s;

  > :last-child {
    margin-bottom: 1rem;
  }

  &::before {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    opacity: .15;
    box-shadow: 0 .25em .5em #263238;
    pointer-events: none;
    transition: opacity .2s;
    z-index: -1;
  }

  &[open] {
    background-color: #FFF;

    &::before {
      opacity: .6;
    }
  }
}

summary {
  padding: 0.5rem 1rem 0.5rem 0;
  display: block;
  position: relative;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;

  &::before,
  &::after {
    width: .75em;
    height: 2px;
    position: absolute;
    top: 50%;
    right: 0;
    content: '';
    background-color: currentColor;
    text-align: right;
    transform: translateY(-50%);
    transition: transform .2s ease-in-out;
  }

  &::after {
    transform: translateY(-50%) rotate(90deg);

    [open] & {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &:focus {
    outline: none;
  }

  &::-webkit-details-marker {
    display: none;
  }
}

.spoiler-body {
    margin-bottom: 3em!important;
/*  p {
    margin: 0 1.5rem 1.5em;
    line-height: 1.5;
  }
*/
  ul {
    margin: 1em 0px 1em 1em;
    padding: 0 0 0 1em;
  }

  li:not(:last-child) {
    margin-bottom: 0.5em;
  }
  
  code {
    padding: 0.2em;
    border-radius: 3px;
    background-color: #E0E0E0;

    pre > & {
      display: block;
      padding: 1em;
      margin: 0;
    }
  }
}

li > p:first-child {
  margin-top: 0;
}

.tooltip {
  display:inline-block;
  position:relative;
  //border-bottom:1px dotted #666;
  text-align:left;
  cursor: pointer;
  
    @media screen and (max-width: 1025px) {
      position: unset;
    }  
}

.tooltip .bottom {
  min-width:250px;
  max-width: 80vw;
  width: 450px;
  top:40px;
  left:50%;
  transform:translate(-50%, 0);
  padding:20px;
  color:#444444;
  background-color:#EEEEEE;
  border-radius:8px;
  position:absolute;
  z-index:99999999;
  box-sizing:border-box;
  box-shadow:0 1px 8px rgba(0,0,0,0.5);
  visibility:hidden; 
  opacity:0;
  border-bottom:1px dotted #666;
  
    @media screen and (max-width: 1025px) {
      width: 80vw;
      max-width: 94vw;
      min-width: 60vw;
      top: unset;
      padding:15px 20px;
    }

    @media screen and (max-width: 500px) {
      width: 94vw;
      max-width: 94vw;
      min-width: 94vw;
      top: unset;
    }
}

.tooltip_container {
  border-bottom: dotted gray 1px;
  margin-right: 3px;
}

.bottom {
  font-weight:normal;
  font-size:16px;
  line-height: 1.3em;

  @media screen and (max-width: 1025px) {
    font-size:14px;
    line-height: 1.1em;
  }
}

.tooltip:hover .bottom {
  visibility:visible;
  opacity:1;
  transition:opacity 0.5s;
}

.greedy-nav a.site-title {
    margin-left: 15px;
}

.fas {
  transform: scale(1);
  position: relative;
  bottom: -4px;
  right: 0;
  opacity: 0.5;
}

.fas:hover {
  opacity: 1;
}

.hide {
  display:none;
}

.select_fw__wrapper {
  display:flex;
  justify-content: center;
}

.select_fw {
    display:flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }
}

.select_fw > input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.select_fw > label {
	background-color: #e4e4e4;
  border-radius: 4px 4px 4px 4px;
	color: rgba(0, 0, 0, 0.6);
	font-size: 22px;
	line-height: 1;
	text-align: center;
	margin: 8px 40px;
	padding: 16px 32px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
  min-width: 220px;
  max-width: 220px;
}

.select_fw > label:hover {
	cursor: pointer;
}

.select_fw > input:checked + label {
  background-color: #a5dc86;
  color: dark-green;
  font-weight: bold;
  box-shadow: none;
}

.scrollup {
  z-index: 9999;
}

.author__urls-wrapper {
  button {
    height: 50px !important;
    min-width: 100px !important;
  }
}


.toc__menu {
  /* hide sub sub links on small screens*/
  li > ul li {
    display: block;
  }
}


.notice--warning, .notice--danger, .notice--info, .notice--success, .notice--primary, .notice, .toc, .author__name {
  font-size: 1rem !important;
  @include breakpoint($large) {font-size: .8rem !important;}
}